@import (reference) '../style/global.less';

.aldPagination {
    :global(.ant-pagination-next),
    :global(.ant-pagination-prev) {
        :global(.ant-pagination-item-link .anticon) {
            font-size: 12px;
        }
    }
}

.aldPagination:not(:global(.mini)) {
    :global(.ant-pagination-item:not(.ant-pagination-item-active)) {
        &:extend(.clickable-element);
    }

    :global(.ant-pagination-item-link) {
        :focus,
        :active {
            box-shadow: unset;
        }
    }

    :global(.ant-pagination-item-link:not([disabled])) {
        &:extend(.clickable-element);
    }

    :global(.ant-pagination-next),
    :global(.ant-pagination-prev) {
        :global(.ant-pagination-item-link) {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}
