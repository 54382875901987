@import (reference) '../../style/global.less';

.success {
    color: @alude-green-6;
}

.error {
    color: @alude-red-5;
}

.warning {
    color: @gold-5;
}
