@import (reference) '../../components/style/global.less';

.container {
    background-color: @alude-gray-0;
}

.page {
    margin-top: 24px;
    width: 100%;

    & > * {
        &:extend(.d-px32);
        &:extend(.mt-px16);
    }
}

.buttonContainer {
    max-width: 100%;
}
