@import (reference) '../style/global.less';

.topBar {
    background-color: @alude-minsk-9;
    color: @alude-gray-0;
    height: @alude-top-bar-height;

    .menu {
        font-size: 24px;

        .logo {
            font-size: 24px;
            &:extend(.pointer);
        }

        & img {
            width: 22px;
            height: 21.27px;
        }
    }
}

.closeIcon > span {
    color: @alude-gray-0;
    top: 8px;
    left: 272px;
    font-size: 24px;
    position: fixed;
}
