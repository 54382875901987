@import (reference) '../style/global.less';

.transparent {
    border: none;
    background-color: transparent;
    padding: 0;
}

.fonts :global(.ant-alert-message) {
    &:extend(.semibold-alude);
}
