@import (reference) '../../style/global.less';

.closeBtn {
    position: absolute;
    &[ant-click-animating-without-extra-node='true'] {
        position: absolute;
    }
    left: 10px;
    top: 10px;
    z-index: 1;
}

.carousel {
    height: 450px;
}

.carouselImg {
    max-width: 100%;
    max-height: 100%;
    // Overriding style in the element itself
    width: auto !important;
    display: block !important;
}

.icon {
    &:extend(.h6-alude);
    text-align: center;

    .imgIcon {
        font-size: 24px;
        color: @alude-blue-6;
    }
}

.icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 8px 24px;
}
