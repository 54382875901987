@import (reference) '../style/global.less';

.container {
    color: @alude-storm-6;
}

.profileIndicator {
    color: @alude-gray-0;
    background-color: @alude-storm-7;
    & > span {
        &:extend(.small-alude);
    }
}

.caret svg {
    font-size: 8px;
}
