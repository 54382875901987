.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    display: none;
}

.content {
    position: relative;
}

.hidden {
    .overlay {
        display: block;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.4) 0%,
            #ffffff 98.23%
        );
    }
}

.button {
    margin-top: 16px;
}

.link {
    padding: 0;
}
