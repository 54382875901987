@import (reference) '../../style/global.less';

.container {
    .title {
        &:extend(.h6-alude);
        color: @alude-storm-9;
    }

    .description {
        &:extend(.regular-alude);
        color: @alude-gray-7;
    }
}

@media (max-width: @mobile-max) {
    .text {
        text-align: center;
    }
}
