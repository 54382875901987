@import (reference) '../../../components/style/global.less';

.background {
    background: @alude-green-1;
}

.alertMessage {
    color: @alude-gray-7;
}

.collapse {
    :global {
        .alude-collapse-item > .alude-collapse-content {
            border-top: 1px solid @alude-gray-4;
        }
    }
}

.tableHeader {
    height: 100% !important;
}
