@import (reference) '../../style/global.less';

.tag {
    margin-right: 0;
}

.container {
    width: 100%;

    .whatsapp {
        color: @alude-green-6;
    }
}
