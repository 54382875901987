@import (reference) '../../style/global.less';

.container .confirmIconColor {
    color: @alude-green-base;
}

.container .failIconColor {
    color: @alude-red-base;
}

.mediumIcon {
    font-size: 20px;
    margin: 1px 4px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
}

.smallIcon {
    font-size: 14px;
    margin: 4px 4px 0 4px;
    vertical-align: middle;
    width: 14px;
    height: 14px;
}
