@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/tag/style/index.less';
@import (reference) '../style/ant-overrides.less';

.make-status-color-classes(@baseColor, @status, @offset: 0) {
    @fontTone: 9 - @offset;
    @bgTone: 1 + @offset;
    @hoverTone: 10 - @offset;
    @fontColor: '@{baseColor}-@{fontTone}';
    @hoverColor: '@{baseColor}-@{hoverTone}';
    @bgColor: '@{baseColor}-@{bgTone}';
    @borderColor: '@{baseColor}-5';

    &-@{status} {
        color: @@fontColor;
        background: @@bgColor;
        border-color: @@borderColor;
    }

    &-close-icon {
        margin-left: 8px;
        display: inherit;

        svg {
            font-size: 16px;
        }
    }

    &-@{status} &-close-icon {
        color: @@fontColor;

        &:hover {
            color: @@hoverColor;
        }
    }
}

@tag-prefix-cls: ~'alude-status-tag';

.@{tag-prefix-cls} {
    &:extend(.regular-alude);
    display: flex;
    width: fit-content;
    height: @component-height-base;
    align-items: center;

    svg {
        font-size: 12px;
    }

    .make-status-color-classes('green', success);
    .make-status-color-classes('alude-blue', processing);
    .make-status-color-classes('alude-red', error);
    .make-status-color-classes('gold', warning);
    .make-status-color-classes('alude-gray', default, 2);
}

.alude-status-tag {
    .alude-status-tag();
}
