@import (reference) '../../style/global.less';

.low {
    color: @alude-gray-6;
}

.average {
    color: @gold-5;
}

.high {
    color: @alude-green-6;
}
