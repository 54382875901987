@import (reference) '../../style/global.less';

.container {
    color: @alude-storm-9;
    & .refresh {
        color: @alude-storm-8;
    }
}

:global(.horizontal.stretch-top) .progress {
    flex: 1 1 auto;
}
