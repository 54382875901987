@import (reference) '../../style/global.less';

.text {
    &:extend(.semibold-alude);
    color: #808080;
}

@media (max-width: @mobile-max) {
    .text {
        order: 1;
    }

    .container button {
        align-self: stretch;
    }
}
