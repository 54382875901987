@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/collapse/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-collapse {
    .alude-collapse();

    & > &-item:last-child,
    & > &-item:last-child > .alude-collapse-header {
        border-radius: 0;
    }

    &-header {
        display: flex;
        font-size: 16px;
        font-weight: 500;
    }

    &-arrow {
        align-self: center;
    }
}
