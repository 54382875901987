@import (reference) '../../style/global.less';

.description {
    color: @alude-minsk-10;
    white-space: pre-wrap;
}

:global(.horizontal.stretch-top) > .textBox {
    flex-basis: 0px;
}

.textBox {
    .title {
        &:extend(.h6-alude);
    }

    color: @alude-gray-9;
}
