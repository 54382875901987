@import (reference) '../../style/global.less';

.container {
    color: #ffffff;

    background: @alude-gradient-green-background;
    border-radius: 4px;
    text-align: center;

    .description {
        &:extend(.h6-alude);
    }

    .balance {
        &:extend(.h1-alude);
    }
}
