@import (reference) '../../style/global.less';

.boleto,
.pix {
    color: @alude-storm-8;
}

.creditCard {
    color: @alude-blue-5;
}

.customer {
    color: @alude-gray-9;
}
