@import (reference) '../style/global.less';

.parentCollapse {
    & :global(.alude-collapse-content) {
        color: @alude-gray-7;
    }
    & :global(.alude-collapse-content-box) {
        padding: 0px !important;
    }
    & :global(.alude-collapse-header) {
        padding: 16px 16px !important;
    }
    :global {
        .alude-collapse-item {
            .alude-collapse-header {
                color: @alude-gray-7;
            }
        }
    }

    &
        > :global(.alude-collapse-item)
        > :global(.alude-collapse-header)
        > :global(.alude-collapse-arrow) {
        top: 0% !important;
        margin-top: 22px !important;
    }
}

.childCollapse {
    & :global(.alude-collapse-content-box) {
        padding: 0 56px 16px 16px !important;
    }
    :global {
        .alude-collapse-item {
            border-top: 1px solid @alude-gray-4;
            background-color: @alude-gray-1;
        }
    }
}

.progressBar {
    display: flex;
    align-items: center;
    gap: 16px;

    font-weight: @alude-font-weight-base;
    font-size: @alude-font-size-base;
    color: @alude-gray-7;
}

.progressBarSteps {
    flex-grow: 1;
    display: flex;
    gap: 4px;
    border-radius: 8px;
    overflow: hidden;
    height: 8px;
}

.progressBarStep {
    flex: 1 1 0;
    background-color: @alude-gray-2;
    height: 8px;
}

.completed {
    background-color: @alude-blue-6;
}

.completing {
    background-color: @alude-gray-2;
    background: linear-gradient(to right, @alude-blue-3 50%, @alude-gray-2 0);
    animation: blink 3s infinite;
    background-size: 200% 100%;
}

.notCompleted {
    background-color: @alude-gray-2;
}

.titleContainer {
    flex: 1;
}

@keyframes blink {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: 0 0;
    }
}
