@import (reference) '../style/global.less';

@table-border: 1px solid @alude-gray-4;

.cell {
    overflow: hidden;
    word-wrap: break-word;
}

.header {
    &:extend(.semibold-alude);

    background: @alude-minsk-0;
    text-transform: uppercase;
}

.row {
    width: 100%;
    border-top: @table-border;
}

.rowHeadless {
    width: 100%;
    &:not(:first-of-type) {
        border-top: @table-border;
    }
}

.data {
    overflow: hidden;
}

.transposedCell {
    overflow-x: hidden;

    .cell {
        text-align: right;
    }
}

.transposedRow {
    &:not(:last-of-type) {
        border-bottom: @table-border;
    }
}

.clickableRow {
    cursor: pointer;

    &:hover {
        background: @alude-gray-1;
    }
}
