@import (reference) '../style/global.less';

.radio {
    composes: alude-radio from global;
}

.block {
    display: flex;
    width: 100%;
    & label {
        flex: 1;
        text-align: center;
    }
}
