@import (reference) '../../style/global.less';

:global(.vertical) .result {
    overflow-x: hidden;
}

.pageUrl {
    color: @alude-green-6;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    max-width: calc(@mobile-max - 100px);
}

.pageTitle {
    &:extend(.semibold-alude);
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    max-width: calc(@mobile-max - 100px);
}

.pageDescription {
    color: @alude-gray-7;
}
