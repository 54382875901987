@import (reference) './Tabs.less';

.alude-filter-tabs.alude-tabs-top > .alude-tabs-nav::before {
    border-bottom: none;
}

.alude-filter-tabs.alude-tabs-top > .alude-tabs-nav {
    &:extend(.d-px16,
    .mt-px12);
}
