.progress {
    composes: alude-progress from global;
}

.roundedProgress :global(.alude-progress-bg) {
    border-radius: 0px 10px 10px 0px !important;
}

.squeeze {
    & :global(.alude-progress-outer) {
        display: flex;
    }
}
