@import (reference) '../style/global.less';

.collapse {
    :global {
        .alude-collapse-item > .alude-collapse-content {
            border-top: 1px solid @alude-gray-4;
        }
    }

    & p {
        margin-bottom: 0px;
    }
}
