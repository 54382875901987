@import (reference) '../../../components/style/global.less';

.listingItemList {
    grid-template-columns: ~'repeat(auto-fit, minmax(min(548px, 100%), 1fr))';
    display: grid;
}

.listingItemList > div {
    border-bottom: 1px solid @alude-gray-4;
}

.container {
    .info:global(.flex-5) {
        flex-basis: 326px;
    }
}

.dotSeparator {
    font-size: 2px;
    vertical-align: middle !important;
}

.listingItem {
    &,
    &:hover,
    &:active {
        color: @alude-gray-9;
    }
    margin: 8px;
    border-radius: 8px;
    transition: background-color- @ant-transition-duration
        @ant-transition-easing;

    &:not(:visited) {
        background-color: white;
    }

    // &:hover {
    //     background-color: @alude-gray-3;
    // }

    &:visited {
        background-color: @alude-gray-3;
    }
}

.visited:before {
    content: 'visualizado';
}
