@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/radio/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-radio-button-wrapper {
    .alude-radio-button-wrapper();
    .alude-radio-group-large & {
        font-size: @font-size-base;
    }
}

.alude-radio-group {
    .alude-radio-group();
    border-radius: @alude-border-radius;
}

.alude-radio-disabled {
    .alude-radio-disabled();
}

.alude-radio-checked {
    .alude-radio-checked();
}

.alude-radio-wrapper {
    .alude-radio-wrapper();
}

.alude-radio-inner {
    &:extend(.clickable-element);
}

.alude-radio-wrapper-disabled .alude-radio-inner {
    box-shadow: none;
    background-color: @alude-gray-2;
}

span.alude-radio + * {
    padding-right: 8px;
    padding-left: 8px;
}

.alude-radio {
    .alude-radio();

    &:hover &-inner {
        border-color: @alude-blue-5;
    }
    &:focus &-inner {
        border-color: @alude-blue-7;
        box-shadow: none;
    }
    &-button-wrapper {
        &:extend(.semibold-alude);
    }
    &-group-solid &-button-wrapper {
        &:first-child {
            border-right: none;
        }

        &:not(:first-child):not(:last-child) {
            border-left: none;
            border-right: none;
        }

        &:last-child {
            border-left: none;
        }
    }

    &-group-solid &-button-wrapper-checked:not(&-button-wrapper-disabled) {
        border-color: @alude-border-color;
        &::before {
            background-color: transparent;
        }
    }
}
