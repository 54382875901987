@import (reference) '../../../components/style/global.less';

.container {
    background-color: @alude-gray-2;
}
.container > div:nth-of-type(odd) {
    background: white;
}

.containerWidth {
    width: 100%;

    & > div {
        max-width: 1000px;
        width: 100%;
    }
}

.countdown {
    color: inherit;
    display: inline;
    & :global(.ant-statistic-content) {
        color: inherit;
        font-size: inherit;
        display: inline;
    }

    & :global(.ant-statistic-content-value) {
        display: inline;
    }
}

.span {
    text-align: center;
}
