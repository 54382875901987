@import (reference) '../style/global.less';

@border: solid 1px @alude-gray-4;

.table {
    display: grid;
    border: @border;
    border-bottom: none;
    border-right: none;
}

.table .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color: @alude-gray-1;
    border: @border;
    border-left: none;
    border-top: none;
}
