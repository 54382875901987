@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/select/style/index.less';
@import (reference) 'antd/lib/empty/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

@import 'antd/lib/select/style/single.less';
@import 'antd/lib/select/style/multiple.less';

@font-size-sm: .regular-alude[font-size];

.alude-select {
    .alude-select();
    border-radius: @alude-border-radius;
    &-item-empty {
        &:extend(.regular-alude);
    }

    &-item,
    &-selection-item {
        color: @alude-gray-9;
    }

    &-focused:not(&-disabled)&:not(&-customize-input) &-selector {
        border-color: @alude-blue-7;
    }

    &-clear,
    &-arrow {
        color: @alude-gray-9;
        top: 50%;
        line-height: 0;
    }

    &-disabled {
        background-color: @alude-gray-2;
    }

    &-item {
        &:extend(.regular-alude);

        &-group {
            &:extend(.semibold-alude);
        }

        &-option-disabled,
        &-empty {
            color: @alude-gray-4;
        }

        &-option {
            &-selected:not(&-disabled) {
                &:extend(.semibold-alude);
            }
        }
    }

    .ald-tag {
        background-color: @select-item-selected-bg;
    }
}
