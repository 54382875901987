@import (reference) '../../style/global.less';

.query {
    float: right;
    text-align: right;
    color: @alude-storm-9;
}

.content {
    box-shadow: 0px -1px 0px @alude-gray-4;
    display: flex;
    flex-wrap: wrap;
}

.content > div {
    width: 50%;
    padding: 8px 0;
}
