.title {
    margin-bottom: 0;
}

.container {
    .info:global(.flex-5) {
        flex-basis: 326px;
    }
}

.containerInfo {
    width: 100%;
}

.ellipsisText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
