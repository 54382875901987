@import (reference) '../style/global.less';

.badge {
    composes: alude-badge from global;
}

.content {
    &:extend(.semibold-alude);
    display: flex;
    align-items: center;
    border-radius: 16px;

    &.round {
        padding: 0 8px;
    }

    &.circle {
        padding: 8px;
    }

    & svg {
        font-size: 12px;
    }

    &.success {
        color: @green-8;
        background-color: @green-2;
    }

    &.error {
        color: @alude-red-8;
        background-color: @alude-red-2;
    }

    &.processing {
        color: @alude-blue-8;
        background-color: @alude-blue-2;
    }

    &.warning {
        color: @gold-8;
        background-color: @gold-2;
    }

    & > *:not(:last-child) {
        margin-right: 4px;
    }
}
