@import (reference) '../style/global.less';

.container.small,
.container.small .child :global(.alude-input-group-addon),
.container.small .child :global(.alude-input-group input) {
    height: @component-height-sm;
}

.container.middle,
.container.middle .child :global(.alude-input-group-addon),
.container.middle .child :global(.alude-input-group input) {
    height: @component-height-base;
}

.container.large,
.container.large .child :global(.alude-input-group-addon),
.container.large .child :global(.alude-input-group input) {
    height: @component-height-lg;
}

.container {
    &:extend(.clickable-element);
    display: flex;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    border-radius: @alude-border-radius;
}

.suffixContainer {
    border-radius: @alude-border-radius;
    border: 1px solid @alude-border-color;
}

.suffix {
    width: max-content;
}

.child {
    box-shadow: none;

    &:first-child,
    &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
        & :global(.alude-input-group-addon) {
            border-radius: 0;
        }
    }

    &:not(:first-child) {
        border-left: none;
    }
}
