@import (reference) '../../style/global.less';

.warningIcon {
    color: @alude-orange-5;
}

.successIcon {
    color: @alude-gray-6;
}

.alertMessage {
    color: @alude-gray-7;
}
