@import (reference) '../style/global.less';

.headed {
    :global {
        .alude-collapse-item > .alude-collapse-header {
            background-color: @alude-minsk-0;
            border-bottom: @alude-card-border;
        }
        .alude-collapse-item:not(:first-child) > .alude-collapse-header {
            border-top: @alude-card-border;
        }
    }
}
