@import (reference) '../style/global.less';

.container.small {
    height: ~'calc(@{component-height-sm} + 2px)';
}

.container.middle {
    height: ~'calc(@{component-height-base} + 2px)';
}

.container.large {
    height: ~'calc(@{component-height-lg} + 2px)';
}

.container {
    border-radius: @alude-border-radius;
    border: @alude-component-border;
    &:extend(.clickable-element);
    display: flex;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
}

.container button {
    border-radius: 0;
    border: none;
    box-shadow: none;
    flex: 1 0 auto;
}

.children button:not(:first-child) {
    border-left: @alude-component-border;
}

.suffix button {
    border-left: @alude-component-border;
}

.children {
    white-space: nowrap;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
}
