@import (reference) '../style/global.less';

.container {
    color: @alude-gray-5;
    height: 64px;

    &.big {
        padding: 8px 20px;
    }
}

.container.big,
.pointsH {
    background-color: rgba(@alude-gray-12, 0.2);
    &:extend(.pointer);

    &:hover {
        background-color: rgba(@alude-gray-12, 0.4);
    }

    transition: background-color @ant-transition-easing @ant-transition-duration;
}

.pointsH {
    padding: 8px;
}

.pointsV {
    display: flex;
    flex-direction: column-reverse;
}

.points {
    &:extend(.h6-alude);
    color: @alude-gray-0;
    &.big {
        &:extend(.h5-alude);
    }
}

.label {
    &:extend(.small-alude);
}

.plus {
    background: @prime-gradient-background;
    & > span {
        font-size: 16px;
    }
}
