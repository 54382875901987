@import (reference) '../style/global.less';

.title {
    color: @alude-gray-7;
}

.avatar {
    color: @alude-gray-9;
    background-color: @alude-gray-4;
}

.children {
    word-break: break-word;
}
