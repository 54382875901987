@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/badge/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-badge {
    .alude-badge();

    & &-status-text {
        &:extend(.regular-alude);
        color: @alude-gray-7;
    }

    &-count {
        position: initial;
    }

    &-status {
        &-success {
            background-color: @green-6;
        }

        &-processing {
            &::after {
                animation: antStatusProcessing 1.2s infinite ease-in-out;
            }
        }
    }
}

@keyframes antStatusProcessing {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        transform: scale(2.4);
        opacity: 0;
    }
}
