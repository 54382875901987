@import (reference) 'antd/lib/style/themes/index.less';
@import (reference) 'antd/lib/tag/style/index.less';
@import (reference) '../style/ant-overrides.less';

.make-status-color-classes(@baseColor, @status) {
    @lightColor: '@{baseColor}-1';
    @lightBorderColor: '@{baseColor}-3';
    @darkColor: '@{baseColor}-6';

    &-@{status} {
        color: @alude-gray-0;
        background: @@darkColor;
        border-color: @@darkColor;
        font-weight: .semibold-alude[font-weight];
    }

    &-close-icon {
        margin-left: 8px;
        display: inherit;

        svg {
            font-size: 16px;
        }
    }

    &-@{status} &-close-icon {
        color: @alude-gray-0;
    }
}

@tag-prefix-cls: ~'alude-color-tag';

.@{tag-prefix-cls} {
    border-color: @alude-gray-4;
    display: inline-flex;
    align-items: center;
    margin: 0;

    &-has-color {
        font-weight: .semibold-alude[font-weight];
    }

    &-close-icon {
        color: @text-color;
    }

    .@{iconfont-css-prefix} {
        font-size: @tag-font-size - 2px;
    }

    .make-status-color-classes('alude-green', success);
    .make-status-color-classes('alude-blue', processing);
    .make-status-color-classes('alude-red', error);
    .make-status-color-classes('alude-orange', warning);
}

.alude-color-tag {
    .alude-color-tag();
}
