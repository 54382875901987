.countdown {
    color: inherit;
    display: inline;
    & :global(.ant-statistic-content) {
        color: inherit;
        font-size: inherit;
        display: inline;
    }

    & :global(.ant-statistic-content-value) {
        display: inline;
    }
}
