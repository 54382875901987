@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/tabs/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-tabs {
    .alude-tabs();
}

.alude-tabs-card {
    .alude-tabs-card();
}

.alude-tabs-dropdown {
    .alude-tabs-dropdown();
}

.alude-tabs-tab:not(.alude-tabs-tab-active) {
    color: @alude-storm-6;
}
