@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/checkbox/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.antCheckboxFn();

@keyframes antCheckboxEffect {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

.alude-checkbox-inner {
    &:extend(.clickable-element);
    border-radius: 2px;
    border-color: @alude-gray-5;
}

.alude-checkbox-checked::after {
    border-radius: 2px;
}

.alude-checkbox-disabled .alude-checkbox-inner {
    border-color: @alude-gray-5;
    background-color: @alude-gray-2;
}

.alude-checkbox-checked .alyude-checkbox-inner {
    border-color: @alude-blue-6;
    background-color: @alude-blue-6;
}

.alude-checkbox-wrapper-disabled .alude-checkbox-inner {
    box-shadow: none;
    background-color: @alude-gray-2;
}

.alude-checkbox-wrapper:hover
    .alude-checkbox:not(.alude-checkbox-checked):not(.alude-checkbox-disabled)
    .alude-checkbox-inner,
.alude-checkbox:hover:not(.alude-checkbox-checked):not(.alude-checkbox-disabled)
    .alude-checkbox-inner {
    border-color: @alude-blue-5;
    &::after {
        background-color: @alude-blue-5;
    }
}

.alude-checkbox:not(.alude-checkbox-checked)
    .alude-checkbox-input:focus
    + .alude-checkbox-inner {
    border-color: @alude-blue-7;
}

.alude-checkbox-group {
    display: inherit;
}
