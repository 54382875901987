@import (reference) '../../style/global.less';

.container {
    --repeat: auto-fit;
    --min-width: 392px;
}

@media (min-width: @tablet-max) {
    .container {
        --repeat: 2;
    }
}

@media (max-width: @mobile-max) {
    .container {
        --min-width: 260px;
    }
}

.container {
    grid-template-columns: ~'repeat(var(--repeat), minmax(var(--min-width), 1fr))';
    display: grid;
    row-gap: 16px;
    column-gap: 16px;
    padding: 16px 32px 32px 32px;
}
