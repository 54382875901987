@import (reference) '../../style/ant-overrides.less';

.container {
    display: inline-flex;
    position: relative;
    background-color: @alude-gray-2;
    border-radius: @border-radius-base;
    overflow: hidden;
    height: 164px;
    min-width: 164px;
}

.noPhoto {
    color: @alude-gray-5;
    position: absolute;
    top: 40%;
    width: 100%;
    min-width: 164px;

    & svg {
        font-size: 24px;
        margin-right: 8px;
    }
}

.tag {
    position: absolute;
    top: 0;
    margin: 8px;
}

.image {
    object-fit: cover;
    width: 100%;
}
