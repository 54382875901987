@import (reference) '../../style/global.less';

.container {
    color: @alude-storm-9;
}

.progress {
    position: relative;
    max-width: 100px;
    height: 50px;
}

.overflow {
    position: relative;
    width: 100px;
    height: 50px;
    overflow: hidden;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 15px solid transparent;
}

.bar1 {
    &:extend(.bar);
    border-left-color: #ff6b6b;
    transform: rotate(15deg);
    background-color: #e4eaf8;
}
.bar2 {
    &:extend(.bar);
    border-left-color: #feca57;
    transform: rotate(105deg);
}
.bar3 {
    &:extend(.bar);
    border-left-color: #15ba8b;
    transform: rotate(165deg);
}

.pointer {
    width: 24px;
    height: 24px;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    box-shadow: inset 0 8px 0 0 rgba(0, 0, 0, 0.1);
    left: calc(50% - 12px);
    top: 36px;
    background-color: #555;
    position: absolute;
}

.pointer.A {
    background-color: #15ba8b;
}
.pointer.B {
    background-color: #15ba8b;
}
.pointer.C {
    background-color: #feca57;
}
.pointer.D {
    background-color: #feca57;
}
.pointer.E {
    background-color: #ff6b6b;
}
.pointer.F {
    background-color: #ff6b6b;
}
