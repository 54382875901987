@import (reference) 'antd/lib/progress/style/index.less';
@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.alude-progress {
    .alude-progress();

    &-status-success &-inner {
        background-color: @alude-green-2;
    }

    &-status-exception &-inner {
        background-color: @alude-red-2;
    }

    &-status-normal &-inner {
        background-color: @gold-2;
    }

    &-status-normal &-bg {
        background-color: @gold-5;
    }
}
