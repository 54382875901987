@import (reference) 'antd/lib/style/index.less';
@import (reference) 'antd/lib/input-number/style/index.less';

@import (reference) '../style/ant-overrides.less';
@import (reference) '../style/global.less';

@ant-prefix: ~'alude';

.out-of-range() {
    border-color: @error-color;
    input {
        color: @text-color;
    }

    &:active,
    &:focus,
    &:hover {
        border-color: @error-color;
    }
}

.alude-input-number {
    .alude-input-number();

    border-radius: @alude-border-radius;
    width: 100%;

    &-handler-up-inner {
        right: 5px;
    }

    &-handler-down-inner {
        right: 5px;
        top: 60%;
    }

    &-focused:not(&-out-of-range),
    &:active:not(&-out-of-range),
    &:focus:not(&-out-of-range) {
        border-color: @alude-blue-7;
    }

    &-focused&-out-of-range,
    &-out-of-range:focus {
        .out-of-range();
        box-shadow: 0 0 0 2px fade(@error-color, @outline-fade);
    }

    &-out-of-range {
        .out-of-range();
        --antd-wave-shadow-color: @error-color;
    }

    &-lg {
        font-size: @font-size-base;
    }

    &-affix-wrapper svg {
        font-size: @font-size-lg;
    }
}
