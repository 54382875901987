@import (reference) '../../style/global.less';

.page {
    display: flex;
    flex-direction: column;
    row-gap: 112px;
    padding-bottom: 112px;
    overflow-x: hidden;
    max-width: calc(100vw - @alude-side-bar-width);

    & > * {
        width: 100%;
    }

    & h2 {
        &:extend(.h3-alude);
    }
}

@media screen and (max-width: @tablet-max) {
    .page {
        max-width: 100vw;
        overflow-x: hidden;
    }
}
