@import (reference) '../../style/global.less';

.container {
    &:extend(.regular-alude);
    color: @alude-storm-9;
}

.avatar:global(.ant-avatar.ant-avatar-icon) {
    font-size: 14px;
}

.avatar {
    background-color: @alude-gray-3;
}
