@import (reference) '../../style/global.less';

.item {
    border-bottom: @alude-component-border;
    border-color: @alude-gray-4;

    &:last-child {
        border-bottom: none;
    }
}

.tag {
    margin-left: auto;
}
