@import (reference) '../../style/global.less';

.stepItem {
    color: @alude-storm-9;
}

.container {
    & > * {
        max-width: 1080px;
    }
}

@media all and (max-width: @tablet-max) {
    .container {
        & > * {
            flex: 1 1 auto;
        }
    }
}
