.colorTag {
    composes: alude-color-tag from global;
}

.statusTag {
    composes: alude-status-tag from global;
}

.clickable {
    composes: clickable-element from global;
    cursor: pointer;
}
