@import (reference) '../style/global.less';

.container {
    background: linear-gradient(
            90deg,
            rgba(@alude-gray-12, 0.2),
            rgba(@alude-gray-12, 0.2)
        )
        @alude-minsk-9;

    .message {
        color: @alude-minsk-2;
    }
}

.message {
    &:extend(.regular-alude);
    color: @alude-minsk-2;
    text-align: center;
}
