@import (reference) '../../components/style/global.less';

.page {
    background-color: @alude-page-bg-color;
}

.content {
    max-width: 100vw;
}

div.container {
    overflow-x: hidden;
}
