@import (reference) '../style/global.less';

.container {
    color: @alude-storm-9;
    & .text {
        flex: 1 1 auto;
    }
}

.icon {
    &.left {
        margin-right: 4px;
    }

    &.right {
        margin-left: 4px;
    }
}

.description,
.title {
    display: flex;
    align-items: center;

    & .icon.right {
        order: 1;
    }

    & .icon.left {
        order: -1;
    }
}

.description {
    &:extend(.regular-alude);
}

.title {
    &:extend(.h5-alude);

    svg {
        height: 14px;
    }
}
