@import (reference) '../style/global.less';

.title {
    &:extend(.h5-alude);
    color: @alude-storm-9;
}

.explanation {
    &:extend(.medium-alude);
    color: @alude-storm-9;
}
