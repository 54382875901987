@import (reference) '../style/global.less';

.sideBar {
    background-color: @alude-minsk-9;
    width: @alude-side-bar-width;
    height: 100vh;
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .logo {
        height: 60px;
        & img {
            height: 18px;
        }
    }
}

.fixed {
    position: fixed;
    z-index: 2;
}
