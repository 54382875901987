@import (reference) '../../style/global.less';

.header {
    border-bottom: 1px solid @alude-gray-4;
}

.title {
    color: @alude-gray-6;
}

.advertiser {
    &:extend(.h5-alude);
    color: @alude-gray-9;
    font-weight: @alude-font-weight-base;
}

:global(.horizontal.stretch-top) > .button {
    flex-basis: 0px;
}
