@import (reference) '../../style/global.less';

.successIcon {
    font-size: 7px;
    margin-right: 4px;
    color: @alude-green-7;
}

.warningIcon {
    font-size: 11px;
    margin-right: 5px;
    color: @gold-6;
}

.text {
    color: @alude-storm-7;
}
