@import (reference) '../../../components/style/global.less';

.cellTextColor {
    color: @alude-gray-7;
}

.transactionIn {
    color: @alude-green-7;
}

.transactionOut {
    color: @alude-red-6;
}

.button {
    height: 22px;
}
