@import (reference) '../../components/style/global.less';

.textSearch {
    & > button {
        min-width: 112px;
    }

    :global(.horizontal) > & {
        flex: 1 1 auto;
    }
}

.clearIcon {
    color: @alude-gray-6;
}
