@import (reference) '../style/global.less';

.tabs {
    composes: alude-tabs from global;
}

.filterTabs {
    composes: alude-filter-tabs from global;
}

.paneBadge {
    color: inherit;
    padding: 2px;
    & > sup {
        background-color: @alude-red-6;
    }
}
