// This solution is specific to the screening page and should not be replicated.
.breadcrumb {
    max-width: 100%;
    flex-wrap: nowrap;
    overflow: hidden;

    & > span {
        flex-shrink: 0;
    }

    & > span:last-child > a {
        max-width: calc(100vw - 116px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div > & {
        flex-shrink: 0;
    }
}
